import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import "../styles/main.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Felix Vogel studiert an der Hochschule für Musik und Darstellende Kunst in Frankfurt' },
            { name: 'keywords', content: 'Felix Vogel, Schauspiel, Frankfurt' },
            { name: 'google-site-verification', content: 'Mbj-a8fExgql4HZ2QqIf0ym6DfXaX1s-iAEljUrQn5E' }
          ]}
        >
          <html lang="de" />
        </Helmet>
        <div className="margin-horizontal">
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
